import { MeterInstance } from './instance.js';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

// Setup debug logging facility

let debug = () => {};

/**
 * Meter Manager
 *
 * This class provides a mechanism for handling meters using a the server for
 * storing the actual meter data. The storage of meters does not use cookies
 * for the data but instead relies on device identifiers stored in local storage
 * and from device fingerprinting. The fingerprint of the device is not used
 * for anything but to make the meter behave in a cookieless fashion.
 *
 * Meters in this model are stored in various ways locally in case the server
 * becomes decynced or the user somehow defeats fingerprinting. In this fall
 * back scenario, a local identifier becomes the key of the communication path.
 * For local storage to work, it must be run in a first party context as ITP
 * in Safari will otherwise block storage access.
 */
export class MeterManager
{
	/**
	 * If enabled, meters will be fetched and synced back to the server
	 * @type {boolean}
	 */
	#bSync = true;

	/**
	 * If debug mode should be enabled for logging purposes
	 * @type {boolean}
	 */	
	#bDebug = false;
	
	/**
	 * The remote web site host that is used to perform syncronization
	 * @type {string}
	 */
	#sHost;
	
    /**
     * Meter instances
     * @type {Object}
     */	
	#oMeters = {};
	
	/**
	 * Device Identifier from fingerprinting process
     * @type {string}
	 */
	#sDeviceID;
	
	/**
	 * The instance ID that is universally unique. This ID is prefered to the
     * device ID as it is pretty much guaranteed to be unqiue but can be wiped
     * by the user
     * @type {string}
	 */
	#sInstanceID;

    /**
     * Assets that have be captured and stored as previously viewed assets
     * @type {Array}
     */	
	#aAssets = [];

	/**
	 * The promise made from the sync operation in the constructor
	 * @type {Promise}
	 */	
	#oSyncPromise;

    /**
     * Constructor
     *
     * @param {Object} oParams
     *  Configuration options to initialize the instance
     */
	constructor(oParams = {})
	{
		this.#sHost = oParams.host ?? null;
		this.#bSync = oParams.sync ?? false;
		this.#bDebug = oParams.debug ?? false;
		
		// Modify the debug function to be enabled
		
		if (this.#bDebug && window?.console?.debug) {
			debug = console.debug.bind(window.console);
		}
		
		if ( oParams.meters ) {

			// Initialize meters potentially based on legacy cookies if set
			
			for ( const oMeter of oParams.meters ) {				
				this.#oMeters[oMeter.id] = new MeterInstance(oMeter.id);
				if ( oMeter.expire_days ) {
					this.#oMeters[oMeter.id].setExpireDays(oMeter.expire_days);
				}
			}			
		}

		debug('Loading list of already viewed assets');

		let sAssets = sessionStorage.getItem('tncms:meter:assets');
		if (sAssets) {
			this.#aAssets = JSON.parse(sAssets) || [];
		}
		
		// Load the local cache when possible
		
		if ( !this.#loadCache() ) {
			this.#oSyncPromise = this.#syncMeters();
		}
	}

	/**
	 * Make sure all meters are loaded first before processing
	 *
	 * @return {Promise}
	 *  Returns true when the syncronization is completed
	 */	
	async ready()
	{
		if (this.#oSyncPromise) {
			await this.#oSyncPromise;
		}
		
		return true;
	}
	
	/**
	 * Loads locally cached meter data
     *
     * Meter cache is only good for the session - each session will load the
     * meters from the server.
	 */
	#loadCache()
	{
		if ( !this.#bSync ) {
			debug('Meter cache not loaded: syncronization disabled');
			return false;
		}
		
		const sCacheID = 'access-' + this.#instanceId;
		
		debug('Checking to see if a meter cache is available');
		
		let sData = sessionStorage.getItem(sCacheID);
		if ( sData ) {
			
			debug('Meter cache detected - unpacking');
			
			const oData = JSON.parse(sData);
			const oNow = new Date();
			
			debug('Initializing meters using the meter cache');
			
			for ( const [sID, oMeter] of Object.entries(oData.meters) ) {
				
				if (oMeter.views <= 0) {
					continue;
				}
				
				let oExpires = new Date(oMeter.expires);
				if (oExpires.getTime() >= oNow.getTime()) {
					this.#setViews(sID, oMeter.views);
				} else {
					debug(`Meter cache for ${sID} skipped - expired`);
				}

			}
			
			this.#sDeviceID = oData.deviceId ?? null;
			
			return true;
			
		}

		debug('No meter cache was found');
		
		return false;
	}
	
	/**
	 * Dynamically determine the meter ID
	 */
	async #detectDeviceID()
	{
		try {
			
			debug('Detecting device identifier');
			
			const oFP = await FingerprintJS.load();
			const oResult = await oFP.get();
			
			debug('Device identified as: ' + oResult.visitorId);
				
			return oResult.visitorId;
			
		} catch (oError) {
			
			console.error('Failed to identify device', oError);			
			
		}
	}

    /**
     * Retrieve the device ID that was detected
     *
     * @param {string}
     *  Retrieve the device ID of the system
     */	
	get #deviceId()
	{
		return this.#sDeviceID;
	}
	
	/**
	 * Get the instance ID
     *
     * @return {string}
     *  The instance ID assigned to the object
	 */
	get #instanceId()
	{
		if ( ! this.#sInstanceID ) {
			
			this.#sInstanceID = localStorage.getItem('tncms:access:iid');
			if ( ! this.#sInstanceID ) {
				this.#instanceId = this.#generateUUID();
				debug('Generated new instance ID: %s', this.#sInstanceID);
			}
			
		}
		
		return this.#sInstanceID;
	}
	
	/**
	 * Set the instance ID of the meter
     *
     * @param {string} sID
     *  The instance ID to set the manager too
	 */
	set #instanceId(sID)
	{
		this.#sInstanceID = sID;
		localStorage.setItem('tncms:access:iid', sID);
	}
	
	/**
	 * Generate a random V4 UUID
     *
	 * Blatantly copied from Stackoverflow post:
     *
     * https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
     * 
     * @return {string}
     *  A randomly generated v4 UUID. Note that this ID is not cryptographically
     *  secure and is for use as a secondary identifier only
	 */
	#generateUUID()
	{
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}
	
	/**
	 * Syncronize meters with the server and local cache
     *
     * This method will syncronize the server meters to the local cache and the
     * in-memory state. This should be called to initialize the local cache if
     * not found and to also notify the sever if meter counts need to be updated
     * with changes.
	 */
	async #syncMeters()
	{
		if ( !this.#bSync) {
			return;
		}
		
		if ( ! this.#sDeviceID ) {
			this.#sDeviceID = await this.#detectDeviceID();
		}
		
		// Fetch the meter state. Note that for the moment credentials are
		// passed along to migrate the legacy cookies that are being used.
		
		debug('Exchanging meter metrics with server');
		
		const oResp = await fetch(`https://${this.#sHost}/tncms/access/meter/`, {
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify(this)
		});
		
		const oResult = await oResp.json();
		
		if (!oResp.ok || oResult?.code ) {
			throw new Error('Failed to sync meters: ' + oResult?.message);
		}
	
		// Based on the server state, syncronize the meters on the client. This
		
		if (oResult.meters) {
			
			debug('Updating local meters based on server state')
			
			for ( const [sID, nViews] of Object.entries(oResult.meters) ) {
				
				this.#setViews(sID, nViews);

			}
			
		}
		
		// Local cache storage of meter data to avoid calling the server
		// unless the meter state changes for the remainder of the session
		
		debug('Updating local meter cache');
		
		sessionStorage.setItem('access-' + this.#instanceId, JSON.stringify({
			deviceId: this.#deviceId,
			meters: this.#oMeters
		}));
		
		console.debug('Sycnronization complete');
		
	}
	
	/**
	 * Check if the provided meter ID is valid
     * 
     * This method should be used to discard access meter methods if invalid and
     * to also treat criteria referencing said meter as being hit. This is a
     * fraud prevention mechanism as tampering or blocking end-points should
     * be treated as effectively a hard wall.
     *
     * @return {boolean}
     *  Returns true if the meter is a valid ID
	 */
	isValidMeter(sID)
	{
		return typeof this.#oMeters[sID] === 'Meter';
	}

    /**
     * Retrieve the current meter count of a meter
     *
     * @param {string} sID
     *  The ID of the meter to resolve
     *
     * @return {number}
     *  The current number of views consumed
     */	
	getViews(sID)
	{		
		if (this.#oMeters[sID]) {
			return this.#oMeters[sID].views;
		}

		throw new Error('No such meter: ' + sID);
	}
	
	/**
	 * Set or create a meter instance
     *
     * @param {string} sID
     *  The ID of the meter to set
     *
     * @param {number} nViews
     *  The number of views to save to the meter
	 */
	#setViews(sID, nViews)
	{
		if ( this.#oMeters[sID] ) {
			
			if (nViews > this.#oMeters[sID].views) {
				this.#oMeters[sID].views = nViews;
			}
			
		} else {
			
			this.#oMeters[sID] = new MeterInstance(sID, nViews);
			
		}
	}
	
	/**
	 * Increment provided meteres by one and optionally sync
     *
     * @param {array} aMeters
     *  An array of meter ID values to syncronize. If none of the meters are
     *  valid, syncronization will automatically be aborted.
	 */
	updateMeters(aMeters = [])
	{
		let bChanged = false;
		
		// Increment the meters provided in the array first
		
		for ( const sID of aMeters ) {
			if (this.#oMeters[sID]) {
				this.#oMeters[sID].increment();
				bChanged = true;
			}
		}
		
		if (bChanged === false) {
			debug('No meters were changed - sync cancelled');
			return;
		}
		
		// Notify the server of the changed meter state
		
		this.#syncMeters();
	}
	
	/**
	 * Check if the asset has been viewed before
     *
     * @return {boolean}
     *  Returns true if the asset has been previously viewed or false otherwise
     *
     * @param {string} sID
     *  The asset ID to verify has already been stored before
	 */
	inAssetList(sID)
	{
		return this.#aAssets.find(oEl => oEl === sID) !== undefined;
	}
	
	/**
	 * Save an asset to the list of previously viewed assets
     *
     * @param {string} sID
     *  The ID of the asset to store in the list
	 */
	saveAssetToList(sID)
	{
		this.#aAssets.push(sID);
		sessionStorage.setItem(
			'tncms:meter:assets',
			JSON.stringify(this.#aAssets)
		); 	
	}
	
	/**
	 * Produce the JSON payload for passing to the meter end-point
     *
     * @return {Object}
     *  The payload that will be converted to JSON
	 */
	toJSON()
	{
		return {
			identifiers: {
				deviceId: this.#deviceId,
				instanceId: this.#instanceId
			},
			meters: this.#oMeters
		}
	}
	
}
