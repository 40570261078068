
/**
 * Meter instance for checking the state of a meter
 */
export class MeterInstance
{
	/**
	 * The number of views in the provided meter instance
     * @type {number}
	 */
	#nViews = 0;
	
	/**
	 * The unique ID of this meter. The ID is returned as part of the rules
     * payload from the server.
	 * @type {string}
	 */
	#sID;
	
	/**
	 * If meter configuration is based on a certain number
	 * of days to expire. If NULL default behavior will be assumed.
	 * @type {number}
	 */
	#nExpireDays;

	/**
	 * Constructor
	 *
	 * @param {string} sID
	 *  Identifier of the meter
	 *
	 * @param {number} nViews
	 *  The number of views to initialize the meter at. This should be queried
     *  from the remote end-point.
	 */
	constructor(sID, nViews = 0)
	{		
		if (typeof sID !== 'string') {
			throw new Error('A string value must be provided for the ID');
		}
		
		if (sID.length == 0) {
			throw new Error('The ID value must not be empty');
		}
		
		this.#sID = sID;
				
		if (nViews === 0) {
			
			this.#nViews = this.#getFromCookie();
			
		} else {
			
			this.views = nViews;
			
		}
	}
	
	/**
	 * Set cookie lifetime based on days.
	 * 
	 */
	setExpireDays( nDays ) {
		this.#nExpireDays = nDays;
	}
	
	increment()
	{
		this.views = this.#nViews+1;
	}
	
	set views(nViews)
	{
		if (typeof nViews !== 'number') {
			throw new Error('Views must provided as a number');
		}
		
		nViews = parseInt(nViews);
		if (nViews < 0) {
			throw new Error('Views must be greater than or equal to zero');
		}
		
		this.#nViews = nViews;
		
		// Set a first party cookie to expire on the first day of next month
		
		if (this.#nViews != this.#getFromCookie()) {
			this.#saveToCookie();
		}

	}
	
	get views()
	{
		return this.#nViews;
	}

	/**
	 * Get the ID of the meter
     *
     * @return {string}
     *  The ID string that was assigned to this meter instance
	 */	
	get id()
	{
		return this.#sID;
	}
	
	get expires()
	{
		let oExpires;
		
		// Existing behavior
		
		if ( ! this.#nExpireDays ) {

			oExpires = new Date();
			oExpires.setDate(1);
			oExpires.setMonth(oExpires.getMonth() + 1);
			oExpires.setHours(0);
			oExpires.setMinutes(0);
			oExpires.setSeconds(0);

		} else {
			
			// Use relative expire days passed from server
			oExpires = this.#getExpireDateFromCookie();
			if ( ! oExpires ) {
				oExpires = new Date();
				oExpires.setDate(oExpires.getDate() + this.#nExpireDays);
			}

		}

		return oExpires;
	}
	
	#getFromCookie()
	{
		try {	
			let aCookies = decodeURIComponent(document.cookie).split(/\s*?;\s*/);		
			for ( const sCookie of aCookies ) {
				if ( sCookie.indexOf('tncms:meter:assets' + this.#sID) === 0 ) {
					let oMatch = sCookie.match(/=(\d+)/);
					if ( oMatch ) {
						return parseInt(oMatch[1]);
					}
				}
			}
		} catch ( oExc ) {
			// Missing or malformed URI component - ignore
		}
		
		return 0;
	}
	
	#getExpireDateFromCookie() {
		try {	
			let aCookies = decodeURIComponent(document.cookie).split(/\s*?;\s*/);		
			for ( const sCookie of aCookies ) {
				if ( sCookie.indexOf('tncms:meter:days' + this.#sID) === 0 ) {
					let oMatch = sCookie.match(/=(.+)/);
					if ( oMatch ) {
						return new Date(oMatch[1]);
					}
				}
			}
		} catch ( oExc ) {
			// Missing or malformed URI component - ignore
		}
		
		return null;		
	}
	
	#saveToCookie()
	{
		document.cookie = 'tncms:meter:assets' + this.#sID + '=' + this.#nViews +
			'; expires=' + this.expires.toGMTString() + '; path=/; SameSite=Strict';
		
		if ( this.#nExpireDays ) {			
			document.cookie = 'tncms:meter:days' + this.#sID + '=' + this.expires.toGMTString() +
			'; expires=' + this.expires.toGMTString() + '; path=/; SameSite=Strict';
		}
	}

	/**
	 * JSON representation of the meter instance
     *
     * @return {Object}
     *  The payload to be converted to a string with JSON.stringify()
	 */	
	toJSON()
	{
		return {
			id: this.#sID,
			views: this.#nViews,
			expires: this.expires
		}
	}
	
}
